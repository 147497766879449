import {
  copyStr
} from '@/utils/function'

export default {
  methods: {
    onCopyOrder(str) {
      try {
        copyStr(str);
        this.$message.success(this.$t('result.copySuccess'));
      } catch (error) {
        console.error(error);
      }
    },

    onReAddOrder(order_id) {
      let params = {
        order_id
      }

      this.$store.commit('changeContentLoading', true);

      this.$axios.ReAddToOrder(params).then(res => {
        const data = res.data;

        if (data && data.code == 0) {
          let orderDetail = data.data;

          this.$router.push(`/paymusic/${orderDetail.order_id}`);
        }

        this.$store.commit('changeContentLoading', false);
      }).catch(() => {
        this.$store.commit('changeContentLoading', false);
      })
    },

    onCancelOrder(scope = {}) {
      let that = this;

      this.$confirm({
        width: '480px',
        title: this.$t('template.confirmCancel', {
          name: this.$t('order.name')
        }),
        // content: '删除该角色，关联该角色的成员账号，会失去对应的权限；',
        onOk() {
          return new Promise((resolve, reject) => {
            that.confirmFunc(scope, resolve, reject);
          });
        }
      });
    },

    confirmFunc(scope, next, reject) {
      let params = {
        order_id: scope.order_id || ''
      }

      this.$axios.CancelOrder(params).then(res => {
        const data = res.data;

        if (data && data.code == 0) {
          this.$message.success(this.$t('template.canceledTip', {
            name: this.$t('order.name')
          }));
          next();
          // this.reloadloadData = true;
          this.$emit('loadlist')
        } else {
          reject()
        }
      })
    },

    onDelOrder(scope) {
      let that = this;

      this.$confirm({
        width: '480px',
        title: this.$t('template.confirmDel', {
          name: this.$t('goods.goods')
        }),
        onOk() {
          return new Promise((resolve, reject) => {
            that.delFunc(scope, resolve, reject);
          });
        }
      });
    },

    goApplyReceipt(scope) {
      this.$emit('applyReceipt', scope);
    },

    goApplyContract(scope) {
      this.$emit('applyContract', scope);
    },

    delFunc(scope, next, reject) {
      let params = {
        order_id: scope.order_id || ''
      }

      this.$axios.DelSingleOrderDetail(params).then(res => {
        const data = res.data;

        if (data && data.code == 0) {
          this.$message.success(this.$t('template.deledTip', {
            name: this.$t('order.name')
          }));
          next();
          // this.reloadloadData = true;
          this.$emit('loadlist')
        } else {
          reject()
        }
      })
    }
  }
}